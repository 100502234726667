import React, { useEffect } from "react";
import {
  createGenerateClassName,
  jssPreset,
  StylesProvider as MuiStylesProvider,
  MuiThemeProvider
} from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core";
import { create } from "jss";
import themeConfig from "config/theme";
import { isClientSide } from "common/helpers";
import { jssInsertionPointId } from "pages/_document";


export const createJSS = () => create({
  ...jssPreset(),
  insertionPoint: isClientSide()
    ? window.document.getElementById(jssInsertionPointId)
    : null,
});

const jss = createJSS();
const generateClassName = createGenerateClassName();


const theme = {
  ...createMuiTheme(themeConfig),
};


const StylesProvider = ({ children }) => {

  // Remove the server-side injected CSS
  const removeSSRcss = () => {
    const element = document.querySelector("#jss-server-side");
    if (element) element.parentNode.removeChild(element);
  };

  useEffect(removeSSRcss);

  return (
    <MuiStylesProvider jss={jss} generateClassName={generateClassName}>
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    </MuiStylesProvider>
  );
};

export default React.memo(StylesProvider);

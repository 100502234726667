import React from "react";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import envVars from "config/envVars";
import { ignoredErrorMessages } from "config/bugsnag";


const getBugsnagClient = () => {
  Bugsnag.start({
    apiKey: envVars.bugsnagApiKey,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => !ignoredErrorMessages.includes(event.errors[0].errorMessage),
    ...(envVars.appVersion ? { appVersion: envVars.appVersion } : {}),
  });

  return Bugsnag.getPlugin("react");
};


const reportingEnabled = !process.env.DEV_MODE && envVars.bugsnagApiKey && envVars.bugsnagReporting;


const ErrorBoundary = reportingEnabled && getBugsnagClient();


const ErrorBoundaryProvider = ({ children }) => ErrorBoundary
  ? (
    <ErrorBoundary>
      {children}
    </ErrorBoundary>
  )
  : children;

export default React.memo(ErrorBoundaryProvider);

import React from "react";
import App from "next/app";
import { AppHeaders, AppLoader } from "client/lib/appLoading";
import ErrorBoundaryProvider from "client/lib/providers/ErrorBoundaryProvider";
import SessionProvider from "client/lib/providers/SessionProvider";
import StylesProvider from "client/lib/providers/StylesProvider";
import UiStateProvider from "client/lib/providers/UiStateProvider";


import "react-image-crop/dist/ReactCrop.css";
import "styles/index.css";


export default class AlumniApp extends App {
  render() {
    const { Component, pageProps } = this.props;
    return (
      <>
        <AppHeaders/>
        <AppLoader>
          <StylesProvider>
            <ErrorBoundaryProvider>
              <UiStateProvider>
                <SessionProvider>
                  <Component {...pageProps} />
                </SessionProvider>
              </UiStateProvider>
            </ErrorBoundaryProvider>
          </StylesProvider>
        </AppLoader>
      </>
    );
  }
}

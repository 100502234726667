import red from "@material-ui/core/colors/red";


const themeConfig = {
  palette: {
    type: "light",
    primary: {
      light: "#6cabd4",
      main: "#387ca3",
      dark: "#005074"
    },
    secondary: {
      light: "#89f6cf",
      main: "#55c39e",
      dark: "#159270",
      contrastText: "#fff"
    },
    error: red
  },
  status: {
    danger: "orange"
  },
  typography: {
    fontFamily: [
      "Muli",
      "Roboto",
      "\"Helvetica\"",
      "Arial",
      "sans-serif"
    ].join(","),
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    useNextVariants: true,
    suppressDeprecationWarnings: true,
    htmlFontSize: 10,
    body1: {
      fontSize: "1.4rem",
    },
    body2: {
      fontSize: "1.4rem",
    }
  },
};

export default themeConfig;
